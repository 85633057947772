<template>
    <div class="event__form">
        <form>
            <div class="event__form-section boxes">
                <label style="margin-right: 2rem;">Anrede*</label>
                <label for="gender-female">
                    <input type="radio" name="gender" id="gender-female" value="Frau" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Frau</div>
                </label>
                <label for="gender-male">
                    <input type="radio" name="gender" id="gender-male" value="Herr" v-model="filterFields(formData ,'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Herr</div>
                </label>
                <label for="gender-none">
                    <input type="radio" name="gender" id="gender-none" value="Keine Angabe" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Keine Angabe</div>
                </label>
            </div>
            <div class="event__form-section fields">
                <label class="title" for="title">
                    <div class="event__text-label">Titel</div>
                    <input type="text" id="title" class="event__input-text" v-model="filterFields(formData, 'form-title').value" @change="validate(formData)"/>
                </label>
                <label class="name" for="name">
                    <div class="event__text-label">Vorname*</div>
                    <input type="text" id="name" class="event__input-text" v-model="filterFields(formData, 'form-name').value" @change="validate(formData)"/>
                </label>
                <label class="lastname" for="surname">
                    <div class="event__text-label">Nachname*</div>
                    <input type="text" id="surname" class="event__input-text" v-model="filterFields(formData, 'form-surname').value" @change="validate(formData)"/>
                </label>
                <label class="company" for="company">
                    <div class="event__text-label">Organisation / Firma*</div>
                    <input type="text" id="company" class="event__input-text" v-model="filterFields(formData, 'form-company').value" @change="validate(formData)"/>
                </label>
                <label class="email" for="email">
                    <div class="event__text-label">E-Mail-Adresse*</div>
                    <input type="email" id="email" class="event__input-text" v-model="filterFields(formData, 'form-email').value" @change="validate(formData)"/>
                </label>
            </div>
            <h3>Weiteres</h3>
            <div class="event__form-section additional">
                <label class="privacy" for="privacy">
                    <input type="checkbox" name="privacy" id="privacy" value="Datenschutz angenommen" v-model="filterFields(formData, 'form-privacy').value" class="event__checkbox" @change="validate(formData)"/>
                    <div class="event__checkmark"></div>
                    <div class="event__checkbox-label">Ich willige ein, dass meine Daten zur Organisation der Veranstaltung bei  NELA. Next Economy Lab verarbeitet werden. Ich kann meine Einwilligung jederzeit, kostenfrei und ohne die Angabe von Gründen widerrufen.</div>
                </label>
                <label class="media" for="media">
                    <input type="checkbox" name="media" id="media" value="Mediennutzung bestätigt" v-model="filterFields(formData, 'form-media').value" class="event__checkbox" @change="validate(formData)"/>
                    <div class="event__checkmark"></div>
                    <div class="event__checkbox-label">Bei den Veranstaltungen werden unter Umständen Foto-, Ton- und Filmaufnahmen angefertigt, die in verschiedenen on- und offline Medien veröffentlicht werden. Diese Aufnahmen sind mit der bildlichen Darstellung von anwesenden Personen verbunden, wobei die Personenauswahl zufällig erfolgt.</div>
                </label>
                <label class="news" for="news">
                    <input type="checkbox" name="news" id="news" value="Newsletter bestätigt" v-model="filterFields(formData, 'form-news').value" class="event__checkbox" @change="validate(formData)"/>
                    <div class="event__checkmark"></div>
                    <div class="event__checkbox-label">Ich möchte über weitere Events und Publikation von NELA. Next Economy Lab informiert werden</div>
                </label>
            </div>
            <div class="event__form-section">
                <label for="hearedfrom">
                    <h3 class="event__text-label">Wie haben Sie von diesem Event erfahren?</h3>
                    <select id="hearedfrom" v-model="filterFields(formData, 'form-heared').value" @change="validate(formData)">
                        <option>Bitte auswählen...</option>
                        <option>Werbung</option>
                        <option>Social Media</option>
                        <option>Einem Bekannten</option>
                    </select>
                </label>
            </div>
            <div class="event__form-section">
                <button class="event__button" :disabled="!valid" @click.prevent="handleSend()">
                    {{ sendButtonText }}
                    <div v-if="isSending" class="loader">
                        <span class="loader__symbol"></span>
                    </div>
                </button>
                <transition name="drop-fade">
                <div v-if="postMessage" class="event__post-message">{{postMessage}}</div>
                </transition>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'DefaultEventForm',
    props: {
        sendButtonText: {
            type: String,
            default: 'Anmelden',
        },
        isSending: {
            type: Boolean,
            default: false,
        },
        postMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            formData: [
                {
                    name: 'form-gender',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-title',
                    required: false,
                    value: '',
                },
                {
                    name: 'form-name',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-surname',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-company',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-email',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-privacy',
                    required: true,
                    value: false,
                },
                {
                    name: 'form-media',
                    required: true,
                    value: false,
                },
                {
                    name: 'form-news',
                    required: false,
                    value: false,
                },
                {
                    name: 'form-heared',
                    required: false,
                    value: '',
                },
            ],
            valid: false,
        }
    },
    methods: {
        validate(formData) {
            let valid = true;
            formData.forEach((field) => {
                if (field.required && !field.value) {
                    valid = false;
                }
            });
            this.valid = valid;
        },
        filterFields(formData, name) {
            return formData.find((field) => field.name === name);
        },
        handleSend() {
            if(!this.valid) {
                return;
            }
            this.$emit('send', this.formData);
        }
    },
} 
</script>