<template>
    <div class="event__form">
        <form>
            <div class="event__form-section boxes">
                <label style="margin-right: 2rem;">Pronouns*</label>
                <label for="gender-female">
                    <input type="radio" name="gender" id="gender-female" value="She/Her" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">She/Her</div>
                </label>
                <label for="gender-male">
                    <input type="radio" name="gender" id="gender-male" value="He/Him" v-model="filterFields(formData ,'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">He/Him</div>
                </label>
                <label for="gender-nonbinary">
                    <input type="radio" name="gender" id="gender-nonbinary" value="They/Them" v-model="filterFields(formData ,'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">They/Them</div>
                </label>
                <label for="gender-none">
                    <input type="radio" name="gender" id="gender-none" value="Prefere no to say" v-model="filterFields(formData, 'form-gender').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Prefere no to say</div>
                </label>
            </div>
            <div class="event__form-section fields">
                <label class="title" for="title">
                    <div class="event__text-label">Title</div>
                    <input type="text" id="title" class="event__input-text" v-model="filterFields(formData, 'form-title').value" @change="validate(formData)"/>
                </label>
                <label class="name" for="name">
                    <div class="event__text-label">First name*</div>
                    <input type="text" id="name" class="event__input-text" v-model="filterFields(formData, 'form-name').value" @change="validate(formData)"/>
                </label>
                <label class="lastname" for="surname">
                    <div class="event__text-label">Last name*</div>
                    <input type="text" id="surname" class="event__input-text" v-model="filterFields(formData, 'form-surname').value" @change="validate(formData)"/>
                </label>
                <label class="company" for="company">
                    <div class="event__text-label">Institution/ organisation*</div>
                    <input type="text" id="company" class="event__input-text" v-model="filterFields(formData, 'form-company').value" @change="validate(formData)"/>
                </label>
                <label class="email" for="email">
                    <div class="event__text-label">E-Mail-Adress*</div>
                    <input type="email" id="email" class="event__input-text" v-model="filterFields(formData, 'form-email').value" @change="validate(formData)"/>
                </label>
            </div>
            <div class="event__form-section">
                Notice: On the second evening, 7th November, we will host the public panel discussion “Development Beyond Growth – From Theory to Practice” from 6-8 p.m. as a hybrid format. This evening event is separate from the conference and open for a broader audience. However, we invite all participants from the conference to attend this panel as well. Please indicate below, if you will attend both (conference and public evening event) or hereby only register for one of them.
            </div>
            <div class="event__form-section fields">
                <label>
                    Please select the event you would like to attend*
                    <select v-model="filterFields(formData, 'form-panel').value">
                        <option>Please select...</option>
                        <option>I would like to attend both the conference and the public evening event</option>
                        <option>I would like to only attend the conference on 6th/7th November</option>
                        <option>I would like to only attend the public evening event on 7th November</option>
                    </select>
                </label>
            </div>
            <div class="event__form-section boxes">
                <label>I will attend as a representative from:</label>
                <label for="research">
                    <input type="radio" name="representing" id="research" value="Research" v-model="filterFields(formData ,'form-representing').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Research</div>
                </label>
                <label for="society">
                    <input type="radio" name="representing" id="society" value="Civil Society" v-model="filterFields(formData ,'form-representing').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Civil Society</div>
                </label>
                <label for="cooperation">
                    <input type="radio" name="representing" id="cooperation" value="Development cooperation" v-model="filterFields(formData ,'form-representing').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Development cooperation</div>
                </label>
                <label for="business">
                    <input type="radio" name="representing" id="business" value="Business" v-model="filterFields(formData ,'form-representing').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Business</div>
                </label>
                <label for="other">
                    <input type="radio" name="representing" id="other" value="Other" v-model="filterFields(formData ,'form-representing').value" class="event__radio" @change="validate(formData)"/>
                    <div class="event__radiomark"></div>
                    <div class="event__radiomark-label">Other</div>
                </label>
            </div>
            <div class="event__form-section big-red">
                Please note: Participation is free of charge. Travel costs must be covered by participants. Due to limited capacities for in-person participation, we ask you to choose in-person participation only if you are certain to be able to attend the conference in Bonn. If we receive more registrations for in-person participation than we have capacity, we will select participants, aiming for a balanced audience with different personal and professional backgrounds. We will inform you as soon as possible whether you can attend in person. As an alternative you are welcome to participate online.
            </div>
            <div class="event__form-section fields">
                <label>
                    I would like to attend in person/ online*
                    <select v-model="filterFields(formData, 'form-attendance').value">
                        <option>Please select...</option>
                        <option>I would like to attend in person</option>
                        <option>I would like to attend online</option>
                    </select>
                </label>
            </div>
            <div class="event__form-section additional">
                <label class="privacy" for="privacy">
                    <input type="checkbox" name="privacy" id="privacy" value="Datenschutz angenommen" v-model="filterFields(formData, 'form-privacy').value" class="event__checkbox" @change="validate(formData)"/>
                    <div class="event__checkmark"></div>
                    <div class="event__checkbox-label">I consent to my data being processed for the organisation of the event by NELA. Next Economy Lab. I can revoke my consent at any time, free of charge and without giving reasons.*</div>
                </label>
                <label class="media" for="media">
                    <input type="checkbox" name="media" id="media" value="Mediennutzung bestätigt" v-model="filterFields(formData, 'form-media').value" class="event__checkbox" @change="validate(formData)"/>
                    <div class="event__checkmark"></div>
                    <div class="event__checkbox-label">I acknowledge that photos, audio and film recordings may be made at the events, which will be published in various online and offline media. These recordings are associated with the visual representation of persons present, whereby the selection of persons is random.*</div>
                </label>
            </div>
            <div class="event__form-section">
                <strong>* Entry mandatory</strong>
            </div>
            <div class="event__form-section">
                <button class="event__button" :disabled="!valid" @click.prevent="handleSend()">
                    {{ sendButtonText }}
                    <div v-if="isSending" class="loader">
                        <span class="loader__symbol"></span>
                    </div>
                </button>
                <transition name="drop-fade">
                <div v-if="postMessage" class="event__post-message">{{postMessage}}</div>
                </transition>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'DefaultEventForm',
    props: {
        sendButtonText: {
            type: String,
            default: 'Anmelden',
        },
        isSending: {
            type: Boolean,
            default: false,
        },
        postMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            formData: [
                {
                    name: 'form-gender',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-title',
                    required: false,
                    value: '',
                },
                {
                    name: 'form-name',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-surname',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-company',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-email',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-panel',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-representing',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-attendance',
                    required: true,
                    value: '',
                },
                {
                    name: 'form-privacy',
                    required: true,
                    value: false,
                },
                {
                    name: 'form-media',
                    required: true,
                    value: false,
                },
            ],
            valid: false,
        }
    },
    methods: {
        validate(formData) {
            let valid = true;
            formData.forEach((field) => {
                if (field.required && !field.value) {
                    valid = false;
                }
            });
            this.valid = valid;
        },
        filterFields(formData, name) {
            return formData.find((field) => field.name === name);
        },
        handleSend() {
            if(!this.valid) {
                return;
            }
            this.$emit('send', this.formData);
        }
    },
} 
</script>