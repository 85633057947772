<template>
  <div v-if="currentRoute" class="event">
    <stage
        :stageLabel="locale === 'en' ? 'Events' : 'Veranstaltungen'"
        class="event__stage-auto-height"
        :big="currentRoute.title.rendered.length < 25"
        :content="currentRoute.acf.facts"
        :signInButton="currentRoute.acf.anmeldung"
        :title="currentRoute.title.rendered"
        :_signInButtonText="locale === 'de' ? 'Anmelden' : 'Sign up'"
        @signInClicked="setSignInOverlay(true)"
    />
    <div class="event__content">
        <div class="event__section-bg">
            <introduction
                :subline="locale === 'de' ? 'Beschreibung' : 'Description'"
                :maintext="currentRoute.acf.description"
                :next="nextLinks"
            />
        </div>
        <h3 v-if="currentRoute.acf.customer" class="event__sectionhead first">{{locale === 'de' ? 'Förderer' : 'Sponsors'}}</h3>
        <ul
            v-if="currentRoute.acf.customer"
            class="event__partner-list"
        >
            <li
                v-for="(customer, i) in currentRoute.acf.customer"
                :key="i"
                class="event__partner-list-item"
            >
                <a v-if="customer.customer_link.url !== '#'" :href="customer.customer_link.url">
                    <h3>{{customer.customer_link.title}}</h3>
                </a>
                <h3 v-else>{{customer.customer_link.title}}</h3>
            </li>
        </ul>
        <h3 v-if="currentRoute.acf.partner" class="event__sectionhead first">{{locale === 'de' ? 'Partner' : 'Partners'}}</h3>
        <ul
            v-if="currentRoute.acf.partner"
            class="event__partner-list"
        >
            <li
                v-for="(partner, i) in currentRoute.acf.partner"
                :key="i"
                class="event__partner-list-item"
            >
                <a v-if="partner.partner_link.url !== '#'" :href="partner.partner_link.url">
                    <h3>{{partner.partner_link.title}}</h3>
                </a>
                <h3 v-else>{{partner.partner_link.title}}</h3>
            </li>
        </ul>
    </div>
    <contact
        class="event__contact"
        v-if="currentRoute.acf.kontakt"
        fontWeight="normal"
        :contact="currentRoute.acf.kontakt"
        :image="currentRoute.acf.kontakt.image ? currentRoute.acf.kontakt.image : {}"
    />
    <div v-if="signInOverlayOpen" @click.self="setSignInOverlay(false)" ref="overlay" class="event__overlay">
        <div class="event__form-wrapper">
            <div @click="setSignInOverlay(false)" class="event__icon-wrapper">
                <icon-close/>
            </div>
            <div class="event__form-head">
                <label>{{ locale === 'de' ? 'Anmeldung' : 'Sign up'}}</label>
                <h2 class="event__form-headline" v-html="currentRoute.title.rendered"/>
            </div>
            <just-transition-event-form v-if="[2771, 2406].includes(this.currentRoute.id)" :isSending="isSending" :postMessage="postMessage" :sendButtonText="sendButtonText" @send="sendContactForm"/>
            <default-event-form v-else :isSending="isSending" :postMessage="postMessage" :sendButtonText="sendButtonText" @send="sendContactForm"/>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/project-intro.vue'
import contact from '@/components/introduction.vue'
import defaultEventForm from '@/components/default-event-form.vue'
import justTransitionEventForm from '@/components/just-transition-event-form.vue'
import IconClose from '@/components/icons/icon-close.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

const postMessages = {
    de: 'Vielen Dank für deine Nachricht. Sie wurde gesendet.',
    en: 'Thank you for your message. It has been sent.'
}

export default {
  name: 'eventDetail',
  components: {
    Stage,
    Introduction,
    contact,
    IconClose,
    defaultEventForm,
    justTransitionEventForm
  },
  data() {
      return {
        postMessage: '',
        signInOverlayOpen: false,
        isSending: false,
        isError: false,
        valid: false,
        booleanLabels: {
            de: {
                true: 'Akzeptiert',
                false: 'Abgelehnt'
            },
            en: {
                true: 'Accepted',
                false: 'Declined'
            }
        }
      }
  },
  computed: {
    ...mapGetters([
        'getFetched'
    ]),
    sendButtonState() {
        return {
            default: this.locale === 'de' ? 'Anmelden': 'Attend',
            sending: this.locale === 'de' ? 'Wird gesendet...': 'Sending...',
            send: this.locale === 'de' ? 'Anmeldung gesendet': 'Message sent',
            error: this.locale === 'de' ? 'Fehler beim senden': 'Error while sending'
        }
    },
    sendButtonText() {
        if(this.isSending) return this.sendButtonState.sending;
        if(this.postMessage === 'success' && !this.isSending) return this.sendButtonState.send;
        if(this.isError) return this.sendButtonState.error;
        return this.sendButtonState.default;
    },
    currentRoute(){
      const getFetched =  this.getFetched[this.$route.params.name];
      return getFetched;
    },
    tagPosts(){
      if (this.currentRoute) {
        return this.currentRoute.postTags ? this.currentRoute.postTags : [];
      }
      return [];
    },
    formID() {
        if([2771, 2406].includes(this.currentRoute.id)) return '3077';
        return '685';
    },
    nextLinks() {
        if(!this.currentRoute.acf.addLinks.length) return [];
        const links = this.currentRoute.acf.addLinks.map(({addLinks_link}) => addLinks_link);
        return links ? links : [];
    },
    locale() {
        return [2771, 2406].includes(this.currentRoute.id) ? 'en' : this.$i18n.locale;
    }
  },
  created() {
      if(!this.currentRoute) {
          this.fetchCurrentPost(this.$route.params.name);
      }
  },
  watch: {
      currentRoute() {
        this.$nextTick(() => {
            if(this.$route.hash === '#register') this.setSignInOverlay(true);
        });
      },
      $route(to) {
        this.$nextTick(() => {
            if(to.hash === '#register') this.setSignInOverlay(true);
        });
      },
  },
  methods: {
    ...mapActions([
          'fetchCurrentPost',
    ]),
    sendContactForm(dataForm) {
        this.isSending = true;
        const form = new FormData();
        form.append('form-event', this.currentRoute.title.rendered);
        form.append('_wpcf7_unit_tag', this.formID);
        dataForm.forEach(elm => {
            if (typeof elm.value === 'string') form.append(elm.name, elm.value);
            if (typeof elm.value === 'boolean') form.append(elm.name, this.booleanLabels[this.locale][elm.value]);
        })
        axios.post(`https://nexteconomylab.de/wp/wp-json/contact-form-7/v1/contact-forms/${this.formID}/feedback`, form)
        .then(res => {
            this.postMessage = res.data.message === 'success' ? postMessages[this.locale]:  'Error';
            this.isSending = false;
        }).catch(() => {
            this.isSending = false;
            this.isError = true;
            this.postMessage = 'Fehler beim Senden der Anmeldung. Bitte versuchen Sie es später erneut.';
        });
    },
    setSignInOverlay(state) {
        if(state) {
            this.signInOverlayOpen = state;
            window.scrollTo({
                top: 0,
                left: 0,
            });
        } else {
            this.signInOverlayOpen = state;
            if(this.$route.hash) this.$router.push({hash: ''});
        }
    },
    validate(dataList) {
        const required = dataList.filter(elm => {
            return elm.required;
        })
        const filled = required.filter(elm => elm.value).length >= required.length;
        
        this.valid = filled;
    }
  }
}
</script>
<style lang="scss">
.event {
    &__stage-auto-height {
        height: auto !important;
        @media (min-width: 1024px) {
            min-height: 442px;
        }
    }
}
</style>
<style lang="scss">
.event {
    margin-top: 86px;
    padding: 0 .8rem;
    margin-bottom: 1.6rem;
    @media (min-width: 1024px) {
    margin-bottom: 150px;
    }
    &__content {
        max-width: 1140px;
        margin: auto;
        overflow: hidden;
        @media (min-width: 768px) {
           padding: 0 1.6rem;
        }
        @media (min-width: 1024px) {
            padding: 0;
        }
    }
    &__sectionhead {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-family:"neue-plak";
        font-weight: 500;
        color: #182952;
        margin: 0 0 0 1.6rem;
        &--left {
            justify-content: flex-start;
        }
        @media (min-width: 768px) {
            margin: 0;
        }
        @media (min-width: 1024px) {
            margin: 0 0 0 110px;
        }
    }
    &__partner-list {
        padding: 0;
        margin: 0;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        @media (min-width: 1140px) {
            padding: 0 110px;
        }
    }
    &__partner-list-item {
        margin: 0;
        list-style-type: none;
        padding: .8rem;
        h3 {
            margin: 0;
        }
    }
    &__section-bg {
        padding:30px 0;
        background-color: #e8edf8;
        position: relative;
        margin-bottom: 65px;
        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            left: calc(50% - 50vw);
            top: 0;
            z-index: -1;
            height: 100%;
            background-color: #e8edf8;
        }
    }
    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.4);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;
    }
    &__form-wrapper {
        max-width: 1200px;
        width: 100%;
        height: auto;
        padding: 1.6rem;
        background-color: #ffffff;
        position: relative;
        margin-top: 80px;
        @media (min-width: 768px) {
            padding: 65px;
            margin-top: 100px;
            border-radius: 25px;
        }
    }
    &__icon-wrapper {
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        width: 30px;
        height: 30px;
        color: inherit;
        cursor: pointer;
    }
    &__form-section {
        display: flex;
        margin-bottom: 1.6rem;
        gap: 1.5rem;

        input[type=text],
        input[type=email],
        input[type=number],
        textarea,
        select {
            border: none;
            outline: none;
            background-color: #f2f2f2;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            padding: .8rem 1rem;
            width: 100%;
            margin-top: .8rem;
        }
        input[type=radio],
        input[type=checkbox] {
            margin-right: .8rem;
            min-width: 1rem;
            min-height: 1rem;
            max-width: 1rem;
            max-height: 1rem;
            width: 1rem;
            height: 1rem;
        }
        &.boxes {
            flex-direction: column;
            
            @media (min-width: 768px) {
                flex-direction: row;
            }

            label {
                display: flex;
                margin-bottom: .8rem;
                @media (min-width: 768px) {
                    margin-bottom: 0;
                }
            }
        }
        &.additional {
            display: flex;
            flex-direction: column;
            label {
                display: flex;
                margin-bottom: 1.6rem;

                &:first-child {
                    margin-right: 1rem;
                }
            }
            @media (min-width: 768px) {
                display: grid;
                grid-template-areas: 
                "privacy media"
                "news .";
                &.privacy {
                    grid-area: privacy;
                }
                &.media {
                    grid-area: media;
                }
                &.news {
                    grid-area: news;
                }
                label {
                    margin-bottom: 0;
                }
            }
        }
        &.fields {
            display: flex;
            flex-direction: column;
            label {
                margin-bottom: 1.6rem;
            }
            @media (min-width:768px) {
                display: grid;
                grid-template-areas: 
                "title title name name name name name lastname lastname lastname lastname lastname"
                "company company company company company company email email email email email email";
                grid-gap: 1.6rem;
                .title {
                    grid-area: title;
                }
                .name {
                    grid-area: name;
                }
                .lastname {
                    grid-area: lastname;
                }
                .company {
                    grid-area: company;
                }
                .email {
                    grid-area: email;
                }  
                label {
                    margin-bottom: 0;
                } 
            }
        }
    }
    &__form-headline {
        font-family: "neue-plak";
        font-weight: 500;
        color: #182952;
        margin-top: 0;
        margin-bottom: 1.6rem;
    }
    &__button {
        margin: 0 auto 0 0;
        text-decoration: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 16px;
        border: none;
        outline: none;
        background-color: #0c3818;
        border-radius: 20px;
        color: #ffffff;
        padding: 8px 24px;
        transition: all 300ms;
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: center;
        &:hover {
            background-color: #5e6534;
        }
        &:disabled {
            background-color: #f2f2f2;
            color: #555555;

        }

        .loader {
            position: relative;
            height: auto;
            background: transparent;

            .loader__symbol {
                height: 1rem;
                width: 1rem;
                border: 2px solid currentColor;
                border-bottom-color: transparent;
            }
        }
    }
    &__contact {
        margin-top: 65px;
        background-color: #e8edf8;
    }
    &__partner-list-item {
        a {
            text-decoration: none;
            color: inherit;
            transition: color 300ms;
            &:hover {
                text-decoration: underline;
                color: #f3972b;
            }
        }
    }
    &__post-message {
        color:#182952;
        font-weight: bold;
    }
    .finances, .knowledge {
        flex-direction: column;
        .form-wrapper {
            display: flex;
            margin: 1rem 0 2rem;
        }

        label {
            display: flex;
            margin-left: 2rem;
            input {
                margin-right: .8rem;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .expectations, .information {
        flex-direction: column;

        textarea {
            border: none;
            outline: none;
            background-color: #f2f2f2;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            padding: .8rem 1rem;
            width: 100%;
        }
    }

    .checkbox {
        display: flex;
        align-items: center;

        .event__checkbox-label {
            flex: 0 0 auto;
            margin-right: 1.6rem;
        }
        .event__input-text {
            margin: 0;
        }
    }
}
</style>
